article.troubleshooting {
	padding: 10px 15px;
}

article.troubleshooting > h2 {
	text-align: center;
	border-bottom: solid 1px #888;
	color: #888;
	font-size: 18px;
}

div.waiting-for-videos {
	width: 100%;
	margin: 0 auto;
	padding: 25px;
	border-radius: 8px;
	background-color: #006eff17;
	box-shadow: #006effa8 1px 1px 5px;
}

div.waiting-for-videos > img {
	width: 40%;
	border-radius: 10px;
	margin: 0 auto;
	display: block;
}

div.waiting-for-videos > p {
	text-align: center;
	font-size: 13px;
	color: #df2e2e;
}

div.waiting-for-videos > a {
	text-align: center;
	display: block;
	width: 100%;
	padding: 10px 0;
	margin: 0;
	font-size: 15px;
	text-align: center;
}

article.troubleshooting div.grid {
	background: #fff;
	box-shadow: #888 1px 1px 5px;
	padding: 25px;
}

/* #region  ul.sessions */
ul.sessions {
	list-style-type: none;
	padding: 0;
	margin: 0;
  display: grid;
  gap: 10px;
}

ul.sessions > li.old {
	padding: 0;
	border: solid 1px #888;
	display: grid;
  border-radius: 5px;
}

ul.sessions > li.old > h2 {
	border-bottom: solid 1px rgba(104, 104, 104, 0.6);
	background-color: rgba(104, 104, 104, 0.6);
	font-family: IranSans;
	font-size: 18px;
	margin: 0;
	padding: 0;
	text-align: center;
	color: #716f6f;
}

ul.sessions > li.old > div > div {
	display: grid;
	grid-template-columns: max-content max-content;
	gap: 10px;
	padding: 10px;
	border-bottom: solid 1px #8888889d;
}

ul.sessions > li.old > div > div > label {
	padding: 0 10px;
	color: #888;
}

ul.sessions > li.old > div > div > label::after {
	content: " : ";
}

ul.sessions > li.new {
	padding: 10px;
}

ul.sessions > li.new > button {
	padding: 10px;
	border: solid 1px #888;
	border-radius: 5px;
	font-family: IranSans;
	background-color: rgb(3, 130, 3);
	color: #fff;
	cursor: pointer;
}

ul.sessions > li.new > button:disabled {
	background-color: rgb(77, 77, 77);
	color: #888;
}

ul.sessions > li.finished {
	background-color: rgba(250, 0, 0, 0.365);
	color: #ff0000;
  padding: 10px;
  border-radius: 5px;
}

/* #endregion */

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	.col-6,.col-4,.w-10{
		width: 100% !important;
	}
}

/*#endregion */
