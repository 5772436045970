body {
  background-image: url("bg.png");
}

article.login {
  width: 400px;
  margin: 80px auto;
  align-content: center;
  box-shadow: #888 1px 1px 5px;
  padding: 10px;
  border-radius: 8px;
  border: solid 1px #c0bebe;
}

article.login > h2 {
  font-size: 16px;
  text-align: center;
  border-bottom: solid 1px #c0bebe;
  padding: 5px;
}

article.login > div.p-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 20px 0px;
}

article.login > div.header {
  position: fixed;
  top: 0px;
  background-color: #57606f;
  left: 0px;
  right: 0px;
  padding: 5px;
}

article.login > div.header > img {
  display: block;
}

article.login div > label {
  width: 100%;
  display: block;
  padding: 10px;
  color: #888;
}

article.login div > input {
  width: 100%;
  display: block;
  direction: ltr;
}

article.login button.mahak-btn {
  background-color: #ffcb05;
  border: solid 0px transparent;
  color: #2f3542;
}

article.login button.mahak-btn.w-100 {
  width: 100%;
}

div.text-danger {
  color: #ff0000;
}

article.login div#password > div {
  width: 100%;
}

article.login div[data-pc-name="inputotp"] {
  direction: ltr;
}

div.or-otp {
  text-align: center;
  padding: 5px;
  position: relative;
}

div.or-otp > span {
  z-index: 99;
  background: #fff;
}

div.or-otp > span::before {
  content: " ";
  border: dotted 1px rgba(109, 105, 105, 0.5);
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  z-index: -9;
  width: 100%;
  left: 0;
}

button.send-again{
  width: 100%;
  text-align: right;
}