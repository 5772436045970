article.online-help {
	padding: 10px 15px;
}

article.online-help > h2 {
	text-align: center;
	border-bottom: solid 1px #888;
	color: #888;
	font-size: 16px;
	padding: 10px;
}

article.online-help p {
	font-size: 14px;
}

article.online-help > ul {
	padding: 0;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 10px;
	list-style-type: none;
}
article.online-help > ul > li {
	background-color: #fff70027;
	border: solid 1px #888;
	box-shadow: #888 1px 1px 5px;
	height: 150px;
	border-radius: 5px;
}
article.online-help > ul > li > a {
	width: 100%;
	height: 100%;
	display: block;
	text-decoration: none;
	position: relative;
	overflow: hidden;
}
article.online-help > ul > li > a > img {
	display: block;
	height: 140px;
	width: 140px;
	border-radius: 5px;
	box-shadow: #888 1px 1px 5px;
	border: solid 1px #b1b0b0;
	object-fit: contain;
	margin: 5px;
	float: right;
}
article.online-help > ul > li > a > span {
	font-size: 15px;
	text-align: center;
	width: 100%;
	display: block;
	padding: 5px;
	color: #795548;
	font-weight: bold;
}

article.online-help > ul > li > a > strong {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%)  rotate(-25deg); 
	opacity: 1;
	color: rgb(72, 182, 72);
	box-shadow: rgb(72, 182, 72) 1px 1px 10px ;
	padding: 15px;
	border: solid 1px rgb(72, 182, 72);
	border-radius: 15px;
}

article.online-help > ul > li[data-isseen="true"] > a {
	opacity: 0.5;
}

article.online-help > ul > li[data-isseen="true"] > a > strong {
	display: block;
}

article.online-help > ul > li > a > p {
	color: #888;
	padding: 0 10px;
	font-size: 13px;
	margin-right: 150px;
	text-align: justify;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	article.online-help > ul > li > a > img {
		height: 80px;
		width: 80px;
	}
	article.online-help > ul > li > a > span {
		font-size: 12px;
	}
	article.online-help > ul > li > a > p {
		margin-right: 80px;
		font-size: 11px;
	}

	article.online-help > ul > li {
		height: auto;
	}

	article.online-help p {
		font-size: 12px;
		text-align: justify;
	}
}

/*#endregion */
