@font-face {
	font-family: "IRANSans-web";
	src: url(/public/fonts/IRANSans-web.eot);
	src: url(/public/fonts/IRANSans-web.eot?#iefix) format("embedded-opentype"),
		url(/public/fonts/IRANSans-web.woff) format("woff"),
		url(/public/fonts/IRANSans-web.woff2) format("woff2"),
		url(/public/fonts/IRANSans-web.ttf) format("truetype");
	font-weight: normal;
	font-style: normal;
}

:root {
	--font-family: "IRANSans-web" !important;
}

html {
	font-size: 0.85rem;
	direction: rtl;
}

a {
	text-decoration: none;
}

a:visited {
	color: #000;
}

.p-icon.p-submenu-icon {
	transform: rotate(180deg);
}

body {
	margin: 0px;
	height: 100%;
	padding: 0 5rem;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: var(--surface-ground);
	font-family: var(--font-family);
	font-weight: normal;
	color: var(--text-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

input.ltr {
	direction: ltr;
}

div.main > header.head {
	background-color: #57606f;
	padding: 5px;
	display: grid;
	grid-template-columns: auto max-content max-content;
}

div.main > header.head > a {
	color: #fff;
	font-size: 22px;
	padding: 5px;
	cursor: pointer;
}

div.main > header.head > img {
	display: block;
}

ul.list-items {
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: repeat(4, auto);
	gap: 20px;
	margin: 0 20px;
}

ul.list-items > li {
	display: block;
}

ul.list-items > li > a {
	border: solid 1px #888;
	background: #efefef;
	display: block;
	height: 160px;
	border-radius: 10px;
	text-decoration: none;
	position: relative;
	text-align: center;
	box-shadow: #88888853 1px 1px 5px;
}

ul.list-items > li > a > img {
	width: 72px;
	margin: 30px 0;
}

ul.list-items > li > a > span {
	background: #cecece;
	border-radius: 0 0 10px 10px;
	font-size: 12px;
	text-align: center;
	height: 36px;
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
	color: #000;
	display: flex;
	justify-content: center;
	align-items: center;
}

ul.list-items > li[data-status="active"] > a > span {
	background-color: #38c338;
}

ul.list-items > li[data-status="deactive"] {
	opacity: 0.6;
}

div.top-toolbar {
	display: grid;
	grid-template-columns: max-content max-content;
	justify-content: end;
	align-items: center;
	gap: 20px;
}

div.top-toolbar > select {
	box-shadow: #888 1px 1px 5px;
	width: auto;
	border: solid 1px #888;
	height: 33px;
	border-radius: 5px;
	padding: 0 5px;
}

div.top-toolbar > ul {
	padding: 0;
	margin: 15px 0;
	list-style-type: none;
	display: grid;
	grid-template-columns: repeat(4, max-content);
	gap: 10px;
	width: 100%;
	justify-content: left;
	padding-left: 10px;
}

div.top-toolbar > ul > li {
	display: block;
}

div.top-toolbar > ul > li > a {
	border: solid 1px #888;
	background: #e2e2e2;
	display: block;
	width: 36px;
	height: 36px;
	border-radius: 5px;
	text-decoration: none;
	position: relative;
	text-align: center;
	box-shadow: #88888853 1px 1px 5px;
}

div.top-toolbar > ul > li > a > i {
	font-size: 24px;
	color: #615d5d;
	line-height: 140%;
}

div.top-toolbar > ul > li > a:hover {
	box-shadow: #ffe100 1px 1px 5px;
}

div.top-toolbar > ul > li > a > img {
	width: 80%;
	margin: 3px;
}

div.main > div.content {
	display: grid;
	grid-template-columns: auto;
}

div.main > div.content.has-menu {
	grid-template-columns: 250px auto;
}

div.main > div.content > div {
	padding: 0;
}

div.main > div.content aside {
	border: solid 1px #e5e5e5;
	border-radius: 5px;
	display: none;
}

div.main > div.content.has-menu aside {
	display: block;
}

div.side-info > div.cart {
	background: #575f6d;
	color: #fff;
	display: grid;
	gap: 10px;
	padding: 15px 8px;
	margin: 6px;
	border-radius: 8px;
	box-shadow: #57606f 1px 1px 4px;
}

div.side-info > div.cart > label {
	color: #e8eaeb;
	font-size: 14px;
}

div.side-info > div.cart > strong {
	color: #ffffff;
	font-size: 18px;
}

div.side-info > div.cart > div.finish {
	margin-top: 10px;
	border-top: solid 1px #aab2b166;
	display: grid;
	grid-template-columns: repeat(2, auto);
	gap: 10px;
}

div.side-info > div.cart > div.finish > label {
	grid-column-start: 1;
	grid-column: 1 / span 2;
}

div.side-info > div.cart > div.finish > strong {
	padding: 5px;
}

div.side-info > div.cart > div.finish > span {
	padding: 5px;
	border-radius: 3px;
	text-align: center;
}

div.side-info > div.cart > div.finish > span.active {
	background: #00ff37;
	color: #312a2a;
}

div.side-info > div.cart > div.finish > span.expired {
	background: #ff5051;
}

div.side-info > ul {
	list-style-type: none;
	padding: 0;
	display: grid;
	gap: 10px;
	margin: 10px;
	background: #fff;
	padding: 5px;
	border-radius: 8px;
	box-shadow: #888 1px 1px 5px;
	margin: 6px;
}

div.side-info > ul > li {
	display: grid;
	border-bottom: solid 1px #88888810;
	padding-bottom: 10px;
}

div.side-info > ul > li > label {
	color: #a5a5a5;
	font-size: 14px;
}

div.side-info > ul > li > h2 {
	font-size: 14px;
	padding: 0;
}

div.side-info > ul > li > a {
	color: #1ea1ff;
	text-decoration: none;
	font-size: 12px;
}

div.ads {
	background: #f4f4f4;
	margin: 0 20px 25px 20px;
	padding: 10px;
	border-radius: 10px;
	box-shadow: #888 1px 1px 3px;
	position: relative;
	text-align: left;
	height: 160px;
}

div.ads > h2 {
	font-size: 18px;
	position: absolute;
	top: 15px;
	right: 50px;
	background: #fbcccc;
	padding: 5px;
}

div.ads > h3 {
	font-size: 18px;
	position: absolute;
	top: 70px;
	right: 50px;
	background: #fbcccc;
	padding: 5px;
}

div.ads > a {
	font-size: 15px;
	position: absolute;
	top: 90px;
	left: 150px;
	background: #ffcb00;
	text-decoration: none;
	padding: 10px;
	border-radius: 10px;
	box-shadow: #888 0px 1px 5px;
	text-align: right;
}

div.ads > img {
	height: 120px;
	margin: 10px 0 0 20px;
}

.p-float-label {
	margin: 10px 0;
}

.p-float-label > label {
	left: auto;
	right: 1rem;
	color: #888;
}

.p-float-label:has(textarea) label {
	right: 1rem;
}

.p-dropdown-filter-icon {
	right: auto;
	left: 0.75rem;
}

.p-dropdown-filter {
	padding-right: auto;
	margin-right: auto;
	padding-left: 1.75rem;
	margin-left: -1.75rem;
}

.site-ltr {
	direction: ltr;
	display: inline-block;
}

div.initial-installation {
	width: 100%;
	margin: 0 auto;
	padding: 25px;
	border-radius: 8px;
}

div.initial-installation > img {
	width: 40%;
	border-radius: 10px;
	margin: 0 auto;
	display: block;
}

div.initial-installation > h2 {
	text-align: center;
	padding: 15px 0;
	margin: 0;
	font-size: 18px;
}

div.initial-installation > p {
	text-align: center;
	font-size: 13px;
}

div.initial-installation.success {
	background-color: #00ff3717;
	box-shadow: #00ff3780 1px 1px 5px;
}

div.initial-installation.waiting {
	background-color: #006eff17;
	box-shadow: #006effa8 1px 1px 5px;
}

div.p-paginator {
	direction: ltr;
}

div.p-password > div {
	width: 100%;
}

div.p-password input {
	width: 100%;
	direction: ltr;
	padding-right: auto;
	padding-left: 2.5rem;
}

span.p-input-icon {
	right: auto;
	left: 0.75rem;
}

/* #region div.additional-service-list */
article.additional-service-list {
	margin: 0 auto;
}

article.additional-service-list > header {
	border-radius: 4px 4px 0 0;
	height: 47px;
	background-color: #ffeb3b;
	background-image: url("header-upgrade-left.png"),
		url("header-upgrade-right.png");
	background-repeat: no-repeat;
	background-position: left center, right center;
}

article.additional-service-list > header > h1 {
	display: block;
	font-weight: bold;
	font-size: 15px;
	line-height: 47px;
	margin-right: 65px;
	color: #795548;
}

article.additional-service-list > img {
	width: 100%;
}

article.additional-service-list > h2 {
	box-sizing: border-box;
	font-weight: bold;
	padding: 5px 10px;
	color: #795548;
	width: 100% !important;
	display: block;
	margin: 0;
	background-color: #fff59b;
	font-size: 14px;
}

article.additional-service-list ul {
	margin: 10px 0;
	padding: 0;
}

article.additional-service-list ul > li {
	display: block;
	border: solid 1px #888;
	margin: 10px 0;
	border-radius: 5px;
	padding: 5px;
	overflow: hidden;
	position: relative;
	min-height: 150px;
}

article.additional-service-list ul > li > img {
	width: 175px;
	height: 100px;
	float: right;
}
article.additional-service-list ul > li > h2 {
	font-size: 15px;
	font-weight: bold;
	display: inline-block;
	margin: 0;
	padding: 10px 15px 0 0;
}
article.additional-service-list ul > li > p {
	padding: 3px;
	line-height: 200%;
	text-align: justify;
	margin-bottom: 50px;
}
article.additional-service-list ul > li > a {
	background-color: #795548;
	box-sizing: border-box;
	padding: 5px 10px;
	margin: 3px auto;
	display: inline-block;
	border-radius: 4px;
	color: #f6f6f6;
	cursor: pointer;
	transition: all ease-in 0.2s 0.1s;
	padding: 10px;
	font-size: 14px;
	border: none;
	font-family: IranSans;
	position: absolute;
	bottom: 10px;
	left: 15px;
}

article.additional-service-list .filter {
	position: relative;
	margin: 10px 0;
}

article.additional-service-list .filter > input {
	padding: 8px 10px;
	font-family: "IranSans-web";
	width: 100%;
	border-radius: 5px;
}

article.additional-service-list .filter > i {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}

/* #region */

/* #region article.additional-service-request */
article.additional-service-request {
}

article.additional-service-request > header {
	border-radius: 4px 4px 0 0;
	height: 47px;
	background-color: #ffeb3b;
	background-image: url("header-upgrade-left.png"),
		url("header-upgrade-right.png");
	background-repeat: no-repeat;
	background-position: left center, right center;
}

article.additional-service-request > header > h1 {
	display: block;
	font-weight: bold;
	font-size: 15px;
	line-height: 47px;
	margin-right: 65px;
	color: #795548;
}

article.additional-service-request > img {
	width: 100%;
}

article.additional-service-request h2 {
	box-sizing: border-box;
	font-weight: bold;
	padding: 5px 10px;
	color: #795548;
	width: 100% !important;
	display: block;
	margin: 0;
	background-color: #fff59b;
	font-size: 14px;
}
article.additional-service-request .grid {
	margin-top: 15px;
}
article.additional-service-request input,
article.additional-service-request select,
article.additional-service-request .p-dropdown {
	border: solid 1px #888;
}
article.additional-service-request input.ltr {
	direction: ltr;
}

article.additional-service-request .amount {
	display: grid;
	text-align: center;
}

article.additional-service-request .amount > strong {
	color: #1e90ff;
	font-weight: bold;
	font-size: 16px;
}

article.additional-service-request .buttons {
	text-align: center;
}

article.additional-service-request > div > button {
	background-color: #795548;
	border-width: 0;
	color: #fff;
	display: inline;
	align-self: center;
}

article.additional-service-request > div > button:disabled {
	background-color: #413b39;
}
article.additional-service-request .file-uploader {
	border: solid 1px #888;
	margin: 10px auto;
	border-radius: 5px;
	overflow: hidden;
}

article.additional-service-request .file-uploader .p-button {
	background-color: #d3d3d3;
	color: #000;
	border: solid 1px #888;
}
article.additional-service-request .file-uploader svg {
	margin: 0 10px;
}
article.additional-service-request .p-inputgroup {
	direction: ltr;
}
article.additional-service-request #requestDate {
	direction: ltr;
	padding: 10px;
	font-family: IranSans;
	width: 100%;
	height: 45px;
}

article.additional-service-request .p-float-label label {
	top: -5px;
}

article.additional-service-request input:read-only {
	background-color: #9c9c9c;
}

/* #endregion */

/* #region article.troubleshooting */
article.troubleshooting input,
article.troubleshooting select,
article.troubleshooting textarea,
article.troubleshooting .p-dropdown {
	border: solid 1px #888;
}

article.troubleshooting .p-float-label label {
	top: -5px;
}

article.troubleshooting input:read-only {
	background-color: #9c9c9c;
}
/* #region */

/* #region article.profile */
article.profile {
	width: 60%;
	margin: 0 auto;
	padding: 30px 10px;
	border: solid 1px #aba9a9;
	background-color: #fff;
	box-shadow: #888 1px 1px 5px;
	border-radius: 5px;
}

article.profile .success h2 {
	text-align: center;
	font-size: 16px;
}

@-webkit-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@-ms-keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes checkmark {
	0% {
		stroke-dashoffset: 50px;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

@-ms-keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

@keyframes checkmark-circle {
	0% {
		stroke-dashoffset: 240px;
	}

	100% {
		stroke-dashoffset: 480px;
	}
}

article.profile svg {
	display: block;
	width: auto;
	margin: 30px auto;
	text-align: center;
	transform: scale(1.5);
}

article.profile svg path {
	-webkit-animation: checkmark 0.55s ease-in-out 1s backwards;
	animation: checkmark 0.55s ease-in-out 1s backwards;
}

article.profile svg circle {
	-webkit-animation: checkmark-circle 1s ease-in-out backwards;
	animation: checkmark-circle 1s ease-in-out backwards;
}
/* #endregion */

input,
.p-dropdown,
textarea {
	border: solid 1px #888;
}

div.ltr {
	direction: ltr;
}

.rmdp-container {
	width: 100%;
}

.rmdp-input {
	margin: 0 !important;
	width: 100% !important;
	height: 40px !important;
	border: solid 1px #888 !important;
	font-family: "IRANSans-web";
	text-align: center;
	color: #4b5563;
}

.p-float-label > label {
	top: -10px;
}

/*#region responsive */

/* Monitor */
@media (min-width: 768px) and (max-width: 992px) {
	ul.list-items {
		grid-template-columns: repeat(4, auto);
		gap: 10px;
		margin: 0 25px;
	}
}

/* Tablet */
@media (min-width: 576px) and (max-width: 768px) {
}

/* Phone */
@media (max-width: 576px) {
	body {
		padding: 0;
	}

	div.main > div.content {
		grid-template-columns: auto;
	}

	div.main > div.content.has-menu {
		grid-template-columns: auto;
	}

	div.main > div.content aside {
		position: fixed;
		z-index: 999;
		background-color: #fff;
		box-shadow: #000 1px 1px 8px;
		border-radius: 0;
		width: 90%;
		right: -100%;
		display: inline;
		transition: all ease-in 0.3s 0.1s;
	}

	div.main > div.content.has-menu aside {
		right: 0;
	}

	div.ads {
		margin: 10px;
		padding: 10px;
		height: 160px;
	}

	ul.list-items {
		grid-template-columns: repeat(2, auto);
		gap: 10px;
		margin: 0 10px;
	}

	div.ads > h2 {
		font-size: 14px;
		top: 15px;
		right: 10px;
	}

	div.ads > h3 {
		font-size: 14px;
		top: 50px;
		right: 10px;
	}

	div.ads > a {
		font-size: 12px;
		top: auto;
		bottom: 10px;
		right: auto;
		left: 160px;
		width: auto;
	}
	div.ads > img {
		height: 80px;
		margin: 60px 0 0 20px;
	}

	div.initial-installation > img {
		width: 100%;
	}

	.col-6,
	.col-3,
	.col-4,
	.col-8,
	.col-9,
	.w-8,
	.w-10 {
		width: 100% !important;
	}

	ul.sessions > li.new > button {
		width: 100%;
	}

	article.additional-service-list ul {
		margin: 10px 10px;
		padding: 0;
	}

	article.additional-service-list ul > li > img {
		width: 100%;
		height: auto;
		float: none;
	}

	article.additional-service-list ul > li > a {
		text-align: center;
		right: 15px;
	}

	article.additional-service-list ul > li > h2 {
		text-align: center;
		padding: 5px;
		width: 100%;
	}

	article.additional-service-request h2 {
		padding: 5px 5px;
		width: 100% !important;
		font-size: 12px;
		text-align: center;
	}

	article.additional-service-request .buttons button {
		width: 100% !important;
	}

	article.additional-service-request .grid {
		margin-top: 15px;
		margin: 15px 10px;
	}

	article.additional-service-request > header {
		height: 80px;
		background-position: left top, right top;
		position: relative;
	}

	article.additional-service-request > header > h1 {
		margin-right: auto;
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		line-height: 0;
		text-align: center;
		padding: 5px;
		font-size: 12px;
	}

	div.top-toolbar {
		grid-template-columns: auto;
	}
}

/*#endregion */
